import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Helmet from "react-helmet"
import {graphql} from "gatsby";

const BlogPage = ({data}) => {
    let siteType = process.env.GATSBY_API_URL;
    let blogData;
    if (siteType === "prod") {
        blogData = data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published");
    } else if (siteType === "dev") {
        blogData = data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
    }
    blogData.sort((a, b) => new Date(b.node.publishDate) - new Date(a.node.publishDate));

    function formatDate(date) {
        var monthNames = [
            "Januar", "Februar", "März",
            "April", "Mai", "Juni", "Juli",
            "August", "September", "Oktober",
            "November", "Dezember"
        ];

        let updateDate = date.split("-");
        let day = updateDate[2];
        let monthIndex = updateDate[1];
        let year = updateDate[0];

        return day + ". " + monthNames[monthIndex - 1] + " " + year;
    }
    let offersData = data.allStrapiOfferPages.edges;
    return (
        <Layout page="blog">
            <Header className="blog" slogan={data.allStrapiBlogPages.edges[0].node.slogan}
                    blogphoto={{backgroundImage: `url(${data.allStrapiBlogPages.edges[0].node.photo.publicURL})`}} offersData={offersData}/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Der Schweizer Voice- und Chatbot Blog | Paixon</title>
                <meta name="description"
                      content="Auf unserem Paixon Blog erfahren Sie Wissenswertes und Neuigkeiten rund um Chatbots, Sprachassistenten und Smart Speaker."/>
                <style type="text/css">{`
            body {
                background-image: none;
            }
          `}</style>
            </Helmet>
            <main className="pt container blog">
                <h1>Paixon Blog</h1>
                <div className="categorySection">
                    <div className="blogsCategory">
                        <a
                            className="categoryButton alleKategorien"
                            href={`/blog`}
                            title='Alle Kategorien'
                        >
                            <p>Alle Kategorien</p>
                        </a>
                        {data.allStrapiBlogCategories.edges.map(category => {
                            return (
                                <a
                                    className="categoryButton"
                                    href={`/blog/${category.node.url}`}
                                    title={category.node.name}
                                    style={{borderColor: category.node.color}}
                                >
                                    <p>{category.node.name}</p>
                                </a>
                            )
                        })}
                    </div>
                </div>
                <section>
                    <div className="blogFlexcontainer">
                        {blogData.map(edge => {
                            const blogpost = edge.node;
                            let updateDate = formatDate(blogpost.publishDate);
                            return (
                                <div className="element nopadding">
                                    <div className="blogcolumn-element">
                                        <div className="blogcolumn">
                                            <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                <div className="blogline"
                                                     style={{
                                                         borderBottom: `10px solid ${blogpost.category.color}`,
                                                         backgroundImage: `url(${blogpost.blog_photo.publicURL})`
                                                     }}>
                                                    <div className="category-icon">
                                                        <div className="triangle-up"
                                                             style={{borderBottom: `45px solid ${blogpost.category.color}`}}>
                                                        </div>
                                                        {data.allStrapiBlogCategories.edges.filter(edges => edges.node.name === blogpost.category.name)
                                                            .map(edge => {
                                                                return (
                                                                    <img src={edge.node.blog_category_icon.publicURL}
                                                                         alt={`${blogpost.category.name} icon`}/>

                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                            <div className="bloginfo">
                                                <p className="publishInfos">{updateDate}</p>
                                                <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                    <h3>{blogpost.title}</h3></a>
                                                <span className="blogpostsummary">{blogpost.summary}</span>
                                            </div>
                                        <a className="readbloglink"
                                           href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                            <div className='readbloglink-text'>Weiterlesen</div>
                                            </a>
                                        </div>
                                    </div>
                            )
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default BlogPage


export const pageQuery = graphql`  
  query BlogQuery {
    allStrapiBlogs {
    edges {
      node {
        author {
          name
        }
        content
        id
        language
        publishDate
        title
        summary
        urlName
        status
        category {
        name
          color
          url
        }
        blog_photo {
          publicURL
        }
      }
    }
  }
 allStrapiBlogCategories {
    edges {
      node {
      color
      name
      url
        blog_category_icon {
          publicURL
        }
      }
    }
  }
  allStrapiBlogPages {
    edges {
      node {
        slogan
        photo {
          publicURL
        }
      }
    }
  }
  allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
  }
`
